/* Custom calendar elements */

.custom-calendar-wrap {
	margin: 10px auto;
	position: relative;
	overflow: hidden;
}

.custom-inner {
	background: #fff;
	box-shadow: 0 1px 3px rgba(0,0,0,0.2);
}

.custom-inner:before,
.custom-inner:after  {
	content: '';
	width: 99%;
	height: 50%;
	position: absolute;
	background: #f6f6f6;
	bottom: -4px;
	left: 0.5%;
	z-index: -1;
	box-shadow: 0 1px 3px rgba(0,0,0,0.2);
}

.custom-inner:after {
	content: '';
	width: 98%;
	bottom: -7px;
	left: 1%;
	z-index: -2;
}

.custom-header {
	background: #fff;
	padding: 5px 10px 10px 20px;
	height: 70px;
	position: relative;
	border-top: 0px solid #000;
	border-bottom: 1px solid #ddd;
}

.custom-header h2,
.custom-header h3 {
	text-align: center;
	text-transform: uppercase;
}

.custom-header h2 {
	color: #495468;
	font-weight: 300;
	font-size: 18px;
	margin-top: 10px;
}

.custom-header h3 {
	font-size: 10px;
	font-weight: 700;
	color: #b7bbc2;
}

.custom-header nav span {
	position: absolute;
	top: 17px;
	width: 30px;
	height: 30px;
	color: transparent;
	cursor: pointer;
	margin: 0 1px;
	font-size: 20px;
	line-height: 30px;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.custom-header nav span:first-child {
	left: 5px;
}

.custom-header nav span:last-child {
	right: 5px;
}

.custom-header nav span:before {
	font-family: 'Titillium Web';
	color: #31708f;
	position: absolute;
	text-align: center;
	width: 100%;
}

.custom-header nav span.custom-prev:before {
	content: '\25c2';
}

.custom-header nav span.custom-next:before {
	content: '\25b8';
}

.custom-header nav span:hover:before {
	color: #495468;
}

.custom-content-reveal {
	background: #f6f6f6;
	background: rgba(246, 246, 246, 0.95);
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 100;
	top: 100%;
	left: 0px;
	text-align: center;
	-webkit-transition: all 0.6s ease-in-out;
	-moz-transition: all 0.6s ease-in-out;
	-o-transition: all 0.6s ease-in-out;
	-ms-transition: all 0.6s ease-in-out;
	transition: all 0.6s ease-in-out;
}

.custom-content-reveal span.custom-content-close {
	position: absolute;
	top: 15px;
	right: 10px;
	width: 20px;
	height: 20px;
	text-align: center;
	background: #31708f;
	box-shadow: 0 1px 1px rgba(0,0,0,0.1);
	cursor: pointer;
	line-height: 13px;
	padding: 0;
}

.custom-content-reveal span.custom-content-close:after {
	content: 'x';
	font-size: 18px;
	color: #fff;
}

.custom-content-reveal a,
.custom-content-reveal span {
	font-size: 22px;
	padding: 10px 30px;
	display: block;
}

.custom-content-reveal h4 {
	text-transform: uppercase;
	font-size: 13px;
	font-weight: 300;
	letter-spacing: 3px;
	color: #777;
	padding: 20px;
	background: #fff;
	border-bottom: 1px solid #ddd;
	border-top: 5px solid #31708f;
	box-shadow: 0 1px rgba(255,255,255,0.9);
	margin-bottom: 30px;
}

.custom-content-reveal span {
	color: #888;
}

.custom-content-reveal a {
	color: #31708f;
}

.custom-content-reveal time{
	display: none;
}

.custom-content-reveal a:hover {
	color: #333;
}

/* Modifications */

.fc-calendar-container {
	height: 400px;
	width: auto;
	padding: 30px;
	background: #f6f6f6;
	box-shadow: inset 0 1px rgba(255,255,255,0.8);
}

.fc-calendar .fc-head {
	background: transparent;
	color: #31708f;
	font-weight: bold;
	text-transform: uppercase;
	font-size: 12px;
}

.fc-calendar .fc-row > div {
	background: #fff;
	cursor: pointer;
}

.fc-calendar .fc-row > div:empty {
	background: transparent;
	cursor: default;
}

.fc-calendar .fc-row > div > span.fc-date {
	top: 50%;
	left: 50%;
	text-align: center;
	margin: -10px 0 0 -15px;
	color: #686a6e;
	font-weight: 400;
	pointer-events: none;
}

.fc-calendar .fc-row > div > span.fc-emptydate {
	color: #ccc;
}

.fc-calendar .fc-row > div.fc-today {
	background: #31708f;
	box-shadow: inset 0 -1px 1px rgba(0,0,0,0.1);
}

.fc-calendar .fc-row > div.fc-today > span.fc-date {
	color: #fff;
	text-shadow: 0 1px 1px rgba(0,0,0,0.1);
}

.fc-calendar .fc-row > div.fc-content:after {
	content: '\00B7';
	text-align: center;
	width: 20px;
	margin-left: -10px;
	position: absolute;
	color: #31708f;
	font-size: 70px;
	line-height: 20px;
	left: 50%;
	bottom: 3px;
}

.fc-calendar .fc-row > div.fc-today.fc-content:after {
	color: #1b3d4d;
}

.fc-calendar .fc-row > div.fc-content:hover:after{
	color: #4CACD9;
}

.fc-calendar .fc-row > div.fc-today.fc-content:hover:after{
	color: #fff;
}

.fc-calendar .fc-row > div > div a,
.fc-calendar .fc-row > div > div span,
.fc-calendar .fc-row > div > div small {
	display: none;
	font-size: 22px;
}

@media screen and (max-width: 400px) {
	.fc-calendar-container {
		height: 300px;
	}
	.fc-calendar .fc-row > div > span.fc-date {
		font-size: 15px;
	}
}
