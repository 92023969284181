/* Titillium fonts */
/* latin-ext */
@font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    src: local('Titillium Web Regular'), local('TitilliumWeb-Regular'), url(../../fonts/Titillium400normal-ext.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}
/* latin */
@font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    src: local('Titillium Web Regular'), local('TitilliumWeb-Regular'), url(../../fonts/Titillium400normal.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}
/* latin-ext */
@font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    src: local('Titillium Web SemiBold'), local('TitilliumWeb-SemiBold'), url(../../fonts/Titillium600normal-ext.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}
/* latin */
@font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    src: local('Titillium Web SemiBold'), local('TitilliumWeb-SemiBold'), url(../../fonts/Titillium600normal.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

/*because top menu bar*/
body {
    padding-top: 50px;
    padding-bottom: 20px;
}

/* strange Android css bugfix */
@media (max-width: 991px) {
    footer {
        font-size: 14px;
    }
}

/* checkbox fix */
input[type="checkbox"] {
    margin-top: 0.4em;
}

/*because valign middle*/
table.abr-table tr td {
    vertical-align: middle;
}

/*navbar*/
.navbar-toggler {
    color: #fff;
}
.white-text {
    color: #fff !important;
}
.bg-inverse {
    background-color: #222 !important;
}
.nav-item.active {
    margin: -0.5rem;
    padding: 0.5rem;
    background-color: #3894b7;
    background-image: linear-gradient(to bottom,#3894b7,#317f9e 100%);
}
.span-nav {
    padding-top: 0.5em;
}

/*labels*/
.label {
    font-style: italic;
    display: inline-block;
    padding: .25em .4em .25em .2em;
    font-size: 75%;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
}
.label-warning {
    background-color: #f0ad4e;
}
.label-danger {
    background-color: #d9534f;
}
.label-info {
    background-color: #5bc0de;
}
.label-success {
    background-color: #5cb85c;
}
.label-active {
    background-color: #0275d8;
    border: solid 1px #0275d8;
}
.label-inactive {
    color: #0275d8 !important;
    border: solid 1px #0275d8;
    cursor: pointer;
    transform: scale(1);
    transition: all .2s ease-in-out;
}
.label-inactive:hover {
    background-color: #5bc0de;
    color: #fff !important;
    border: solid 1px #5bc0de;
    transform: scale(1.2);
}
.input-group label {
    margin-top: .5rem;
    margin-right: .5rem;
}
.h5-filter {
    padding-top: .4rem;
}

/*popover*/
.my-popover {
    cursor: pointer
}
.my-popover sup i {
    color: #0275d8;
}

/* featured tournament */
.featured-tournament {
    text-align: center;
}
.featured-tournament > a > img {
    width: 100%;
    height: auto;
    padding: 0.5em 0;
}

/*bracket style, collision with JQBracket*/
:not(.jQBracket) > .bracket{
    position: relative;
    padding: 0.5em 1em 1em 1em;
    margin: 1em 0em;
}
:not(.jQBracket) > .bracket:before, :not(.jQBracket) > .bracket:after{
    content: '';
    display: block;
    height: 100%;
    width: 25px;
    border: 2px solid #333;
    position: absolute;
    top: -5px;
}
:not(.jQBracket) > .bracket:before{ left: 0; border-right: 0; pointer-events: none; }
:not(.jQBracket) > .bracket:after{ right: 0; border-left: 0; pointer-events: none; }

.bracket-mini{
    display: inline-block;
    position: relative;
    padding: 0.25em 0.5em 1em 0.5em;
    margin-top: 2em;
}
.bracket-mini:before, .bracket-mini:after{
    content: '';
    display: block;
    height: 100%;
    width: 15px;
    border: 1px solid #333;
    position: absolute;
    top: -5px;
}
.bracket-mini:before{ left: 0; border-right: 0; pointer-events: none; }
.bracket-mini:after{ right: 0; border-left: 0; pointer-events: none; }

.navbar-brand-image {
    float: left;
    height: 30px;
    padding: 0;
    margin: 0 10px;
}

.page-header {
    border-bottom: 2px solid #000;
    padding-bottom: 9px;
    margin: 40px 0 20px;

    /*font-size: 24px;*/

    /*font-family: inherit;*/
    /*font-weight: 500;*/
    /*line-height: 1.1;*/
    /*color: inherit;*/
}

/*user numbers on homescreen*/
.user-counts {
    font-size: 80%;
}

/*footer stuff*/
.legal-bullshit {
    font-size: 0.7em;
    font-style: italic;
}

/*Embedded Google map*/
.map-wrapper {
    height: 400px;
    width: 100%;
}

.map-wrapper-small {
    height: 250px;
    width: 100%;
}

.map-placeholder {
    background-image: url("/img/blurred_map.png");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: #ccc;
    display: table;
}
.map-placeholder-cell {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

#map {
    height: 100%;
    width: 100%;
}

.btn-xs {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}
.table th {
    border: 0;
}

/* worlds in results table */
.row-worlds {
    background-color: #dad9fa !important;
}
.row-selected {
    background-color: #ccdafa !important;
}
.row-danger {
    background-color: #facecd !important;
}

.form-group.row {
    margin-bottom: 0;
}

h5 small {
    font-size: 70%;
}
h6 {
    font-size: 1.1rem;
}
input[type="checkbox"] {
    vertical-align: text-top;
}

/*limiting image size in tournament description markdown*/
.markdown-content img {
    max-width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

/* ID hotness on homepage */
.hot-id {
    text-align: center;
    padding-top: 1em;
}
.hot-id a img {
    width: 70%;
    max-width: 155px;
    height: auto;
}
.small-text {
     font-size: 80%;
     font-style: italic;
     line-height: 1.2;
}
.small-bold {
    font-size: 80%;
    font-weight: bold;
}

/* 1st player ID in table */
.cell-winner {
    min-width: 54px;
}
.cell-winner img {
    margin: 0.2rem 0;
    width: 20px;
    height: 20px;
}
.cell-winner-v {
    max-width: 40px;
}
.cell-winner-v img {
    width: 26px;
    height: 26px;
}
.cell-winner-v img:first-of-type {
    margin-bottom: 2px;
}


/* id images */
img.id-small {
    width: 20px;
    height: 20px;
}
img.id-medium {
    width: 40px;
    height: 40px;
}

/* 30x30 images */
.image-30x30 {
    width: 30px;
    height: 30px;
}

/* viewing as admin/creator */
.view-indicator {
    font-size: 80%;
    padding: 0.5rem;
    margin: 0.5rem 0;
}

/*integration on homepage*/
.integration {
    text-align: center;
    padding-top: 1em;
}
.integration .hint {
    font-size: 80%;
    font-style: italic;
}
.integration img {
    max-width: 100%;
    height: auto;
}

/*import user in entries table*/
.import-user {
    font-style: italic;
    font-size: 90%;
    vertical-align: middle !important;
}

/*modal*/
.modal-title {
    line-height: 1.2;
}
.modal-subtitle {
    font-size: 80%;
    font-style: italic;
}
.card-title {
    padding-bottom: 1rem;
    font-weight: bold;
}

/*overlay to disable parts*/
.overlay {
    position: absolute;
    top: -5px;  /*because bracket style*/
    left: 0;
    right: 0;
    bottom: 5px;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9;
    color: white;
    display: inline-block;
}
.overlay div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.overlay a {
    color: #ccc;
    font-weight: 800;
}

/*white transparency overlay, "more" */
.more-overlay {
    background: linear-gradient(to bottom,rgba(255,255,255,0),rgba(255,255,255,1));
    position: absolute;
    top: 320px;
    bottom: 0;
    left: 0;
    right: 0;
}
.more-container {
    min-height: 400px;
    max-height: 400px;
    overflow: hidden;
    position: relative;
    display: block !important;
}
.more-image-overlay {
    background: linear-gradient(to bottom,rgba(255,255,255,0),rgba(255,255,255,1));
    position: absolute;
    top: 220px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 20;
}
.more-image-container {
    min-height: 300px;
    max-height: 300px;
    overflow: hidden;
    position: relative;
    display: block !important;
}

/*small refresh icon*/
.small-right-icon {
    float: right;
    bottom: -0.25em;
    margin-top: 0.75em;
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}


/*button for claiming*/
.btn-claim {
    color: #fff;
    background-color: #b87434;
    border-color: #b87434;
}
.btn-claim:hover {
    color: #fff;
    background-color: #945e2b;
    border-color: #945e2b;
}

/*button for concluding tournaments*/
.btn-conclude {
    color: #fff;
    background-color: #ae5cb8;
    border-color: #ae5cb8;
}
.btn-conclude:hover {
    color: #fff;
    background-color: #8b4195;
    border-color: #8b4195;
}
/*button for managing tournaments*/
.btn-manage {
    color: #fff;
    background-color: #5ca4b8;
    border-color: #5ca4b8;
}
.btn-manage:hover {
    color: #fff;
    background-color: #4a8494;
    border-color: #4a8494;
}

/*loader animation*/
.loader {
    color: #000;
    opacity: 0.8;
    min-height: 50px;
    z-index: 10;
    width: 100%;
    position: absolute;
    text-align: center;
    margin-top: 2rem;
    font-style: italic;
}
.loader:before {
    content: '[';
    display: inline-block;
    -webkit-animation: pulse 0.4s alternate infinite ease-in-out;
    animation: pulse 0.4s alternate infinite ease-in-out;
    margin-right: 5px;
    font-weight: bold;
    font-style: normal;
}
.loader:after {
    content: ']';
    display: inline-block;
    -webkit-animation: pulse 0.4s alternate infinite ease-in-out;
    animation: pulse 0.4s alternate infinite ease-in-out;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
    margin-left: 5px;
    font-weight: bold;
    font-style: normal;
}
.loader-chart {
    color: #000;
    opacity: 0.8;
    width: 100%;
    position: relative;
    text-align: center;
    margin: 1rem 0;
    font-style: italic;
}
.loader-chart:before {
    content: '[';
    display: inline-block;
    -webkit-animation: pulse 0.4s alternate infinite ease-in-out;
    animation: pulse 0.4s alternate infinite ease-in-out;
    margin-right: 5px;
    font-weight: bold;
    font-style: normal;
}
.loader-chart:after {
    content: ']';
    display: inline-block;
    -webkit-animation: pulse 0.4s alternate infinite ease-in-out;
    animation: pulse 0.4s alternate infinite ease-in-out;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
    margin-left: 5px;
    font-weight: bold;
    font-style: normal;
}
@-webkit-keyframes pulse {
    to {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
        opacity: 0.5;
    }
}
@keyframes pulse {
    to {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
        opacity: 0.5;
    }
}

/*active filters*/
.active-filter {
    background-color: #a3ccff;
    font-weight: bold;
}

/*markdown help*/
.help-markdown {
    font-family: Menlo, Monaco, monospace;
    background-color: #ddd;
    font-size: 80%;
}

blockquote {
    border-left: 5px solid #ccc;
    margin: 1.5em 10px;
    padding: 0.5em 10px;
}

/*responsive full height columns*/
.row-height {
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
}
.col-height {
    display: table-cell;
    float: none;
    height: 100%;
}
.inside-full-height {
    height: 100%;
    margin-top: 0;
    margin-bottom: 0;
}
.inside-bottom-center {
    left: 0;
    right: 0;
    position: absolute;
    bottom: 1.25rem;
}
.button-spacer {
    padding-top: 3rem;
}

@media (min-width: 480px) {
    .row-xs-height {
        display: table;
        table-layout: fixed;
        height: 100%;
        width: 100%;
    }
    .col-xs-height {
        display: table-cell;
        float: none;
        height: 100%;
    }
}

@media (min-width: 768px) {
    .row-sm-height {
        display: table;
        table-layout: fixed;
        height: 100%;
        width: 100%;
    }
    .col-sm-height {
        display: table-cell;
        float: none;
        height: 100%;
    }
}

@media (min-width: 992px) {
    .row-md-height {
        display: table;
        table-layout: fixed;
        height: 100%;
        width: 100%;
    }
    .col-md-height {
        display: table-cell;
        float: none;
        height: 100%;
    }
}

@media (min-width: 1200px) {
    .row-lg-height {
        display: table;
        table-layout: fixed;
        height: 100%;
        width: 100%;
    }
    .col-lg-height {
        display: table-cell;
        float: none;
        height: 100%;
    }
}
/*responsive linebreaks*/
@media (max-width: 1199px) {
    .line-breaker {
        display: block;
    }
}
.line-breaker {
    white-space: nowrap;
}

/* map legend for map icons */
.map-legend-icon {
    height: 20px;
    width: auto;
}

/*calendar, only show reoccur symbol once*/
.fc-calendar-events > .fc-calendar-event + .fc-calendar-event .recurring {
    display: none;
}
.recurring {
    color: #aaa;
}
.fc-content.fc-content-hidden:after {
    display: none !important;
}

/* 500 page */
.stacktrace {
    font-family: monospace;
    font-size: 70%;
    text-align: left;
}

/*table height*/
.table-doublerow td {
    height: 4em;
}

/*notification badges*/
.notif-badge {
    position:relative;
}
.active.notif-badge[data-badge]:after {
    top:0.25rem;
    right:-0.25rem;
}
.notif-badge[data-badge]:after {
    content:attr(data-badge);
    position:absolute;
    top:-0.25rem;
    right:-0.75rem;
    font-size:.7em;
    width:18px;height:18px;
    text-align:center;
    line-height:18px;
    border-radius:50%;
    box-shadow:0 0 1px #333;
}
.notif-badge-page {
    position:relative;
}
.notif-badge-page[data-badge]:after {
    content:attr(data-badge);
    position:absolute;
    top:-0.5rem;
    left:-0.5rem;
    font-size:.7rem;
    width:1rem;height:1rem;
    text-align:center;
    line-height:1rem;
    border-radius:50%;
    box-shadow:0 0 1px #333;
}
.notif-red:after {
    background: darkred;
    color:white;
}
.notif-green:after {
    background:#BFDC7A;
    color:#6a6340;
}
/* new badge for profile badges */
.new-badge:after {
    content:'NEW';
    position:absolute;
    top:0;
    right:0;
    font-size:.7em;
    text-align:center;
    border-radius:10%;
    box-shadow:0 0 1px #333;
    font-weight: bold;
    padding: 0 3px;
}
.new-badge {
    position: relative;
    display: inline-block;
}
.inline-block {
    display: inline-block;
}

/* for a elements with no href */
.fake-link {
    color: #0275d8 !important;
    text-decoration: underline;
    cursor: pointer;
}

/* anchor fix */
a.anchor {
    display: block;
    position: relative;
    top: -75px;
    visibility: hidden;
}

/* match table */
.row-colored {
    background-color: rgba(0, 0, 0, 0.05);
}
.table-match {
    width: 100%;
}
.table-match td {
    padding: 0.1em 0.3em;
}
.table-match img {
    padding: 0 3px;
}
#table-matches-top td {
    width: 50%;
}
/* double elimination iframe */
#iframe-tree {
    width: 100%;
    height: 0;
    border: 0;
}
/*darker cards*/
.card-darker {
    background-color: #eeeeee;
}
.card-darker hr {
    border-top: 1px solid rgba(0,0,0,0.3);
}
/*dashed hr*/
hr.dashed {
    border-top: 1px dashed rgba(0,0,0,0.3) !important;
}
/* points cell */
.cell-points {
    line-height: 1em;
    text-align: right;
    padding: 0.1rem;
}
.img-inline {
    height: 1em;
    margin-top: -0.2em;
    margin-right: 0.2em;
}
/* supporter username */
.supporter {
    color: #d89602 !important;
}
a.supporter:hover {
    color: #8e6101 !important;
}
/* admin username */
.admin {
    color: #af66d8 !important;
}
a.admin:hover {
    color: #844ea3 !important;
}
/* artist username */
.artist {
    color: #eb3c21 !important;
}
a.artist:hover {
    color: #c92b13 !important;
}

/*modal tabs*/
.modal-tabs > ul.nav-tabs {
    background-color: #eee;
}
.modal-tabs > ul.nav-tabs > li > a:not(.active){
    color: #777 !important;
}
.modal-tabs > ul.nav-tabs > li > a:not(.active):hover {
    color: #55595c !important;
}

/* tournament type indicator on profile */
.tournament-type {
    display: inline-block;
    padding: .25em .5em;
    font-weight: bold;
    line-height: 1rem;
    color: #fff;
    border-radius: .25rem;
    z-index: 10;
    font-style: normal;
}
.no-li {
    margin-left: -1.6rem;
}
.type-store {
    background-color: #3a8922;
}
.type-regional {
    background-color: #722086;
}
.type-national {
    background-color: #24598a;
}
.type-continental {
    background-color: #8a5e25;
}
.type-world {
    background-color: #892222;
    padding: .25em .3em;
}
.type-worlds {
    background-color: #892222;
    padding: .25em .3em;
}
.type-team {
    background-color: rgba(51, 137, 230, 1);
    font-size: 70%;
    position: relative;
    top: -0.15em;
    padding: 0.4em 0.5em !important;
}
.type-circuit {
    background-color: rgb(51, 230, 90);
    font-size: 70%;
    position: relative;
    top: -0.15em;
    padding: 0.4em 0.5em !important;
}
.type-async {
    background-color: rgb(230, 51, 141);
    padding: .25em .3em;
}
/* tournament format */
.tournament-format {
    display: inline-block;
    background-color: #fff;
    font-weight: 800;
    line-height: 1rem;
    border-radius: .25rem;
    z-index: 10;
    font-style: normal;
    border: 0.1rem solid;
}
.type-onesies {
    color: #46bc7d;
    border-color: #46bc7d;
    padding: .25em .55em .25em .45em;
}
.type-cache {
    font-size: 70%;
    position: relative;
    top: -0.1em;
    color: #5d9ebc;
    border-color: #5d9ebc;
    padding: .35em .5em .25em .5em;
 }
.type-draft {
    border-color: #bc6657;
    color: #bc6657;
    padding: .25em .5em .25em .45em;
}
.type-cube-draft {
    font-size: 70%;
    position: relative;
    top: -0.1em;
    color: #bc6db1;
    border-color: #bc6db1;
    padding: .35em .5em .25em .5em;
}
.type-recurring {
    background-color: #fff;
    color: #575a5c;
}
/* new types */
.type-startup {
    font-size: 70%;
    position: relative;
    top: -0.1em;
    color: #bc6657;
    border-color: #bc6657;
    padding: .35em .5em .25em .5em;
 }
 .type-snapshot {
    font-size: 70%;
    position: relative;
    top: -0.1em;
    color: #bc9457;
    border-color: #bc9457;
    padding: .35em .5em .25em .5em;
 }
 .type-eternal {
    border-color: #ab57bc;
    color: #ab57bc;
    padding: .25em .5em .25em .45em;
}
.type-other {
    border-color: black;
    color: black;
    padding: .25em .5em .25em .45em;
}
/* gallery */
.gallery-item {
    display: inline-block;
    text-align: center;
    max-width: 100%;
    overflow: hidden;
    margin-bottom: 0.5em;
}
.abs-top-left {
    position: absolute;
    top: 5px;
    right: 5px;
}
.fade-in {
    opacity: 0.5;
}
.fade-in:hover {
    opacity: 1;
}
.ekko-lightb.ox .modal-footer {
    text-align: right !important;
    font-style: italic;
}
.ekko-lightbox .modal-title {
    display: inline !important;
}
.featured-header {
    position: relative;
}
.featured-bg {
    background-size: contain;
    opacity: 0.2;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
}
.support-bg {
    background-color: #fee3a9;
}
.stuff {
    padding: 0.5em;
    text-shadow: 1px 1px white;
}
.stuff .tournament-type {
    text-shadow: none !important;
}
.featured-box {
    margin-bottom: 1em;
    border: 1px solid black;
    padding: 0 !important;
}
.featured-box table {
    margin-bottom: 0.5em;
}
.featured-footer {
    margin-bottom: 0.5em;
}
.featured-images {
    height: 3em;
    text-align: center;
    margin-bottom: 0.5em;
    overflow: hidden;
}
.featured-images img {
    max-height: 100%;
    width: auto;
    display: inline-block;
}
.featured-title {
    height: 1.7em;
    overflow: hidden;
}
.featured-box table td {
    padding: .5em !important;
    vertical-align: middle;
}
@media (min-width: 992px) {
    .featured-col {
        padding: 0;
    }
}

.t-list-footer {
    margin: .5em 0 0 0;
    text-align: center;
    font-size: 80%;
    font-style: italic;
    line-height: 1.2;
}
table.hover-row > tbody > tr:hover > td {
    background-color: #ccc;
    cursor: pointer;
}
.video-thumbnail {
    max-width: 120px;
    height: auto;
}
.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
img.badge {
    width: 70px;
    height: 70px;
}
/* hiding past calendar events */
.fc-past {
    cursor: default !important; background: transparent !important;
}
.alert-small {
    font-style: italic;
    font-size: 12px;
    margin: 0 5px;
    padding:  1px 5px;
}
/* profile broken link */
.broken-deck-profile {
    margin-left: -2px;
    vertical-align: middle;
}
.icon-upper {
    color: #0275d8 !important;
    font-size: 80%;
    vertical-align: top;
}
/* contact icons in footer */
.contact-icon {
    color: #373a3c;
    font-size: 150%;
    vertical-align: bottom;
}
/* responsive font size */
@media (max-width: 991px){
    body {
        font-size: 18px;
    }
    h4 {
        font-size: 2rem;
    }
    h5 {
        font-size: 1.7rem;
    }
    table.abr-table td.tournament-title a {
        font-size: 22px;
    }
    td.action i.fa {
        font-size: 20px;
    }

    /* responsive navbar size */
    nav {
        font-size: 150%;
    }
    .body-container {
        padding-top: 0.5em;
    }
    .navbar-brand {
        font-size: 150%;
        max-width: 70%;
        max-height: 1.7em;
        overflow: hidden;
    }
    nav div button {
        font-size: 150% !important;
        margin-left: 0.5em;
    }
    img.image-30x30{
        width: auto;
        height: 1.5em;
    }
    .nav-main {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    #navbar-collapse-1 {
        padding-top: 0.5em;
    }
    nav li {
        font-size: 120%;
    }
    .notif-badge[data-badge]:after {
        font-size: 0.5em !important;
    }
    nav ul {
        width: 100%;
        padding: 0 1em !important;
    }
    .span-nav {
        padding-top: 0 !important;
    }
}
@media (max-width: 767px){
    body {
        font-size: 20px;
    }
}

/* patreon */
.img-patron-o {
    content: url(/img/logo_patreon_o.png);
    height: 1em;
    max-height: 1em;
}

.country-flag {
    vertical-align: sub;
    margin-right: 0.2em;
    width: 30px;
    height: 20px;
}

/* subscribe to personal calendar */
#info-subscribe {
    left: 1rem;
    top: 3rem;
    position: absolute;
    z-index: 999;
    border: 1px solid #aaa;
    padding: 0.5rem 1rem;
    width: calc(100% - 2rem);
    width: -moz-calc(100% - 2rem);
    width: -webkit-calc(100% - 2rem);
    background-color: #eee;
    border-radius: 0.25rem;
    overflow: hidden;
}

table.hover-row > tbody > tr:hover > td {
    background-color: #ccc;
    cursor: pointer;
}

input[type="search"]::-webkit-search-cancel-button {
    /*Bootstrap 4 override*/
    -webkit-appearance: searchfield-cancel-button;
}
.highlight {
    background-color: yellow;
}

/* number input on prize collection*/
.input-collection {
    width: 2em;
    text-align: center
}
.input-collection::-webkit-inner-spin-button,
.input-collection::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

[v-cloak] { display: none; }

table.prize-collection {
    width: 100%;
}
table.prize-collection td {
    vertical-align: top;
    padding: 0.2em;
}
/* add photo on profile art page */
.shrink100x100 {
    max-width: 100px;
    max-height: 100px;
    margin: 2px;
}
.size100x100 {
    width: 100px;
    height: 100px;
}
.flex-row {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}
.ellipis-overflow {
    overflow: hidden; 
    text-overflow: ellipsis; 
    display: block;
}
/* responsive checkerboard background */
.checkered-md:nth-of-type(2n+1) {
    background-color: rgba(0, 0, 0, 0.05);
}
@media (min-width: 768px) {
    .checkered-md:nth-of-type(4n+1), .checkered-md:nth-of-type(4n) {
        background-color: rgba(0, 0, 0, 0.05) !important;
    }
    .checkered-md:nth-of-type(4n+2), .checkered-md:nth-of-type(4n+3) {
        background-color: rgba(0, 0, 0, 0.00) !important;
    }
}

/* v-autocomplete for unofficial prize selection */
.v-autocomplete {
    position:relative;
    width: 100%;
}
.v-autocomplete .v-autocomplete-list {
    position:absolute
}
.v-autocomplete .v-autocomplete-list .v-autocomplete-list-item {
    cursor:pointer
}
.v-autocomplete .v-autocomplete-list-item .v-autocomplete-item-active {
    background-color:#f3f6fa
}
.v-autocomplete-input {
    display: block;
    width: 100%;
    padding: 0.5rem 0.75rem;
    font-size: 1rem;
    line-height: 1.25;
    color: #55595c;
    background-color: #fff;
    background-image: none;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
}
.v-autocomplete-list {
    border: 2px solid rgba(0, 0, 0, 0.35);
    padding: 0.25rem;
    border-radius: 0.25rem;
    background-color:#fff;
    z-index: 500;
}
.v-autocomplete-item-active:hover {
    background-color: #cce5ff;
}
.v-autocomplete-selected .v-autocomplete-input {
    background-color: #edf7ef !important;
    border-color: #c5e7cd !important;
}
.border-left-flat {
    border-top-left-radius: 0; 
    border-bottom-left-radius: 0;
}
.v-autocomplete-preview {
    max-height: 38px; 
    max-width: 38px;
    margin: auto 0;
}
.font-1-3 {
    font-size: 1.3em;
}

/* prizes */
.table-prizes {
    margin: 0 auto; 
    width: 90%;
}
.qr-code {
    max-width: 50%;
    height: auto;
}