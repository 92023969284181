@font-face {
  font-family: 'fontawesome-selected';
  src: url("../../fonts/fontawesome-webfont.eot");
  src: url("../../fonts/fontawesome-webfont.eot?#iefix") format('embedded-opentype'), url("../../fonts/fontawesome-webfont.woff") format('woff'), url("../../fonts/fontawesome-webfont.ttf") format('truetype'), url("../../fonts/fontawesome-webfont.svg#fontawesome-selected") format('svg');
  font-weight: normal;
  font-style: normal;
}

.fc-calendar-container {
	position: relative;
	height: 400px;
	width: 400px;
}

.fc-calendar {
	width: 100%;
	height: 100%;
}

.fc-calendar .fc-head {
	height: 30px;
	line-height: 30px;
	background: #ccc;
	color: #fff;
}

.fc-calendar .fc-body {
	position: relative;
	width: 100%;
	height: 100%;
	height: -moz-calc(100% - 30px);
	height: -webkit-calc(100% - 30px);
	height: calc(100% - 30px);
	border: 1px solid #ddd;
}

.fc-calendar .fc-row {
	width: 100%;
	border-bottom: 1px solid #ddd;
}

.fc-four-rows .fc-row  {
	height: 25%;
}

.fc-five-rows .fc-row  {
	height: 20%;
}

.fc-six-rows .fc-row {
	height: 16.66%;
	height: -moz-calc(100%/6);
	height: -webkit-calc(100%/6);
	height: calc(100%/6);
}

.fc-calendar .fc-row > div,
.fc-calendar .fc-head > div {
	float: left;
	height: 100%;
	width:  14.28%; /* 100% / 7 */
	width: -moz-calc(100%/7);
	width: -webkit-calc(100%/7);
	/* width: calc(100%/7); */ 
	position: relative;
}

/* IE 9 is rounding up the calc it seems */
.ie9 .fc-calendar .fc-row > div,
.ie9 .fc-calendar .fc-head > div {
	width:  14.2%;
}

.fc-calendar .fc-row > div {
	border-right: 1px solid #ddd;
	padding: 4px;
	overflow: hidden;
	position: relative;
}

.fc-calendar .fc-head > div {
	text-align: center;
}

.fc-calendar .fc-row > div > span.fc-date {
	position: absolute;
	width: 30px;
	height: 20px;
	font-size: 20px;
	line-height: 20px;
	font-weight: 700;
	color: #ddd;
	text-shadow: 0 -1px 0 rgba(255,255,255,0.8);
	bottom: 5px;
	right: 5px;
	text-align: right;
}

.fc-calendar .fc-row > div time {
	display: none;
}

.fc-calendar .fc-row > div > span.fc-weekday {
	padding-left: 5px;
	display: none;
}

.fc-calendar .fc-row > div.fc-today {
	background: #fff4c3;
}

.fc-calendar .fc-row > div.fc-out {
	opacity: 0.6;
}

.fc-calendar .fc-row > div:last-child,
.fc-calendar .fc-head > div:last-child {
	border-right: none;
}

.fc-calendar .fc-row:last-child {
	border-bottom: none;
}
