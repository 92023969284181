@font-face {
    font-family: 'netrunner';
    src:    url('../../fonts/netrunner.eot');
    src:    url('../../fonts/netrunneriefix.eot') format('embedded-opentype'),
    url('../../fonts/netrunner.ttf') format('truetype'),
    url('../../fonts/netrunner.woff') format('woff'),
    url('../../fonts/netrunner.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

.icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'netrunner' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-subroutine:before {
    content: "\e900";
}
.icon-mu3:before {
    content: "\e901";
}
.icon-mu2:before {
    content: "\e902";
}
.icon-mu1:before {
    content: "\e903";
}
.icon-mu:before {
    content: "\e904";
}
.icon-trash:before {
    content: "\e905";
}
.icon-link-half-top:before {
    content: "\e906";
}
.icon-link-half-bottom:before {
    content: "\e907";
}
.icon-link:before {
    content: "\e908";
}
.icon-click:before {
    content: "\e909";
}
.icon-recurring-credit:before {
    content: "\e90a";
}
.icon-credit:before {
    content: "\e90b";
}
.icon-core:before {
    content: "\e914";
}
.icon-genesis:before {
    content: "\e911";
}
.icon-creation-and-control:before {
    content: "\e913";
}
.icon-spin:before {
    content: "\e90c";
}
.icon-honor-and-profit:before {
    content: "\e910";
}
.icon-lunar:before {
    content: "\e90f";
}
.icon-order-and-chaos:before {
    content: "\e90e";
}
.icon-sansan:before {
    content: "\e90d";
}
.icon-data-and-destiny:before {
    content: "\e912";
}
.icon-mumbad:before {
    content: "\e91f";
}
.icon-anarch:before {
    content: "\e91a";
    color: orangered;
}
.icon-criminal:before {
    content: "\e919";
    color: royalblue;
}
.icon-shaper:before {
    content: "\e91b";
    color: limegreen;
}
.icon-jinteki:before {
    content: "\e916";
    color: crimson;
}
.icon-haas-bioroid:before {
    content: "\e918";
    color: blueviolet;
}
.icon-nbn:before {
    content: "\e915";
    color: darkorange;
}
.icon-weyland-cons:before {
    content: "\e917";
    color: darkgreen;
}
.icon-adam:before {
    content: "\e91d";
    color: darkgoldenrod;
}
.icon-apex:before {
    content: "\e91e";
    color: darkred;
}
.icon-sunny-lebeau:before {
    content: "\e91c";
    color: black;
}